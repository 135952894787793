import React from "react"
import Layout from "@components/layout";
import Animation from "@components/kaigai-fx/animation.js";

// Import SVG
import corp_busimg1 from '../images/busi_img01.png';
import corp_busimg2 from '../images/busi_img02.png';
import corp_busimg3 from '../images/busi_img03.png';
import media_img02 from '@images/top_media_img01_2.webp';


// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Business = () => {
  const pageFXUrl = '/kaigai-fx/';
  
  // Seo情報設定
  const pathName = 'business';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div className={pathName}>
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">事業内容</h1>
        </div>
        <div>
          <div className=" text-left mb-[30px] max-w-xs md:max-w-5xl md:mx-auto mx-6 md:mt-[30px] mt-[50px]">
            <h2 className="pt-2 font-semibold tracking-wide text-2xl md:text-3xl ">Our Business</h2>
          </div>
          <Animation customType='falling-item' loop='loop'>
          <div className="bus_flex max-w-xl mx-auto md:max-w-5xl mb-[60px]">
            <div className="bus_thum">
              <p className="mb-5"><img src={corp_busimg1} alt=""/></p>
            </div>
            {/* <a id="bus1"className="pt-[70px] mt-[-70px]"></a> */}
            <div className="bus_thum md-px-0 px-6">
              <h3 className="text-lg font-semibold leading-6 text-[#04183B]">蓄積したノウハウを利用した<br/>自社オウンドメディアの運営</h3>
              <p className="mt-2">集客の向上を目的とした、SEO対策やデジタルマーケティング、快適で軽快な動作のサイト構築に取り組んだオウンドメディアの運営を行っております。</p>
            </div>
          </div>
          </Animation>
          <Animation customType='falling-item' loop='loop'>
          <div className="bus_flex max-w-xl mx-auto md:max-w-5xl mb-[60px]">
            <div className="bus_thum od1">
              <p className="mb-5"><img src={corp_busimg2} alt=""/></p>
            </div>
            {/* <a id="bus2" className="pt-[70px] mt-[-70px]"></a> */}
            <div className="bus_thum od2 md-px-0 px-6">
              <h3 className="text-lg font-semibold leading-6 text-[#04183B]">効果的なsns利用とweb広告の運用<br/>デジタルマーケティング支援</h3>
              <p className=" mt-2">SNSを活用したマーケティング支援やSEO対策、事業規模や広告予算に応じて最適な広告をご提案します。<br/>
              計測された数値を元にロジカルな運用で取り組んでいます。
              </p>
            </div>
          </div>
          </Animation>
          <Animation customType='falling-item' loop='loop'>
          <div className="bus_flex max-w-xl mx-auto md:max-w-5xl mb-[60px]">
            <div className="bus_thum">
              <p className="mb-5"><img src={corp_busimg3} alt=""/></p>
            </div>
            {/* <a id="bus3" className="pt-[70px] mt-[-70px]"></a> */}
            <div className="bus_thum md-px-0 px-6">
              <h3 className="text-lg font-semibold leading-6 text-[#04183B]">クライアントの要望と利用者のニーズに応える<br/>トレンドをおさえたWeb制作</h3>
              <p className=" mt-2">SEO対策やサイトの読み込みスピード向上を重要視したサイト制作を請け負っております。<br/>
              クライアントの満足感と利用者の快適性を両立させた提案を行います。
              </p>
            </div>
          </div>
          </Animation>
        </div>
        <div className="xl:px-0 px-5">
          <div className=" text-left mb-[30px] max-w-xs md:max-w-5xl md:mx-auto mx-6 md:mt-[30px] mt-[50px]"> 
            <h2 className="pt-2 font-semibold tracking-wide text-2xl md:text-3xl ">Media</h2>
          </div>
          <Animation customType='falling-item' loop='loop'>
          <div className=" md:flex max-w-xs md:max-w-5xl mx-auto">
            <div className="max-w-[200px] md:mx-0 mx-auto">
              <a href={pageFXUrl} aria-label="海外FX200"><img src={media_img02} alt="" width="382" height="212"/></a>
            </div>
            <div className="md:pl-5 md:mt-0 mt-[30px] md:w-[65%]">
              <h3 className="text-[14px]  font-semibold leading-6">海外FX200</h3>
              <p className="md:mt-2 mt-2 font-semibold text-lg text-[#04183B]">海外FX業者の比較やブローカー別に特徴をまとめた 比較サイトです。</p>
              <p className="mt-2">これからFXを始めたいと思っているビギナーの方からすでに取引を行っている経験者のために、各種お役立ち情報を更新してまいります。</p>
              <p className="mt-5 text-xs mb-20 text-[#07306A] underline focus:no-underline"><a href={pageFXUrl}>ページはこちら</a></p>
            </div>
          </div>
          </Animation>
        </div>
      </div>
    </Layout>
  </>
  )
}
export default Business

